export const DATA_IDS = {
  PRE_ASSET: {
    LOADING: "pre-asset-loading",
    ERROR: "pre-asset-error",
    RETRY: "pre-asset-retry",
  },
  SELECT_ASSETS: {
    SEARCHABLE_INPUT: "select-assets-searchable-input",
    NO_BALANCE_SCREEN: "select-assets-no-balance",
    NO_BALANCE_TRADE_BUTTON: "select-assets-trade-button",
    UNSUPPORTED_ASSETS_COPY: "select-assets-unsupported-assets-copy",
  },
  CONFIRMATION_TABLE: {
    ADDRESS_LABEL: "address_label",
    ADDRESS: "address",
    EDIT_EXCHANGE: "edit_exchange",
    EDIT_ENTITY: "edit_entity",
    EDIT_COUNTRY: "edit_country",
    SKELETON_LOADER: "skeleton_loader",
    MEMO: "memo",
    NETWORK: "network",
    VASP_ID: "vasp_id",
    ATTESTATION_TYPE: "attestation_type",
    COUNTRY: "country",
    FEE: "fee",
    MAX_FEE: "max_fee",
    AMOUNT: "amount",
    TOTAL: "total",
    CONTINUE: "continue",
  },
  SELECT_APPROVED_ADDRESS: {
    SEARCHABLE_INPUT: "select-approved-address-searchable-input",
    EMPTY_STATE: "empty-state",
  },
  NON_WHITELISTED_ADDRESS: {
    EDIT_ADDRESS: "edit-non-whitelisted-address",
    CLOSE_ICON: "close-icon",
    SELECTED_ADDRESS: "selected-address",
    CLIPBOARD_ADDRESS: "clipboard-address",
  },
  ASSET_ERROR: {
    GO_BACK: "asset-error-go-back",
    TRANSACTION_DISABLED: "transaction-disabled",
    CONTENT: "asset-error-content",
  },
  UK_ATTESTATION: {
    CONTENT: "uk-attestation-content",
  },
  CWB: {
    CONTENT: "cwb-content",
  },
  AMOUNT_WITHDRAWAL_SCREEN: {
    AMOUNT_INPUT: "amount-input",
    CONTINUE_BUTTON: "continue-button",
    DESTINATION_ADDRESS_CTA: "destination-address-cta",
    DESTINATION_ADDRESS_CARD: "destination-address-card",
    NOTIONAL_VALUE: "notional-value",
    AVAILABLE_FOR_WITHDRAWAL: "available-for-withdrawal",
    MAX_BUTTON: "max-button",
  },
  MAS_ATTESTATION: {
    SUBMIT_ATTESTATION_BUTTON: "submit-attestation-button",
    DECLINE_ATTESTATION_BUTTON: "decline-attestation-button",
    ATTESTATION_DONE_BUTTON: "address-attested-done-button",
  },
  NEW_ADDRESS_FLOW: {
    CONFIRM: "new-address-flow-confirm",
    DECLINE: "new-address-flow-decline",
    UK_SELECT_REASON_CONTINUE: "uk-select-reason-continue",
    UK_FEEDBACK_CONFIRM: "uk-feedback-confirm",
    UK_FEEDBACK_DECLINE: "uk-feedback-decline",
    EU_SELECT_REASON_CONTINUE: "eu-select-reason-continue",
    EU_FEEDBACK_CONFIRM: "eu-feedback-confirm",
    EU_FEEDBACK_DECLINE: "eu-feedback-decline",
  },
  ADD_MEMO: {
    INPUT: "memo-input",
    CLIPBOARD: "clipboard",
  },
  WITHDRAWAL_WARNING: {
    UNDERSTAND_BUTTON: "understand-button",
    LEARN_MORE: "learn-more",
    CONTENT: "withdrawal-warning-content",
  },
  WITHDRAW_APPROVAL: {
    WITHDRAW_CANCEL_BUTTON: "withdraw-button-cancelWithdraw",
    WITHDRAW_CONFIRM_BUTTON: "withdraw-button-confirmWithdraw",
  },
  COMPLETE_WITHDRAWAL: {
    COMPLETE_WITHDRAWAL_CONTENT: "complete-withdrawal-content",
    WITHDRAWAL_AMOUNT_TITLE: "complete-withdrawal-title-amount",
  },
  WITHDRAWAL_SUCCESS: {
    WITHDRAWAL_SUCCESS_CONTENT: "withdrawal-success-content",
    DONE: "withdrawal-success-done",
    VIEW_DETAILS: "withdrawal-success-view-details",
  },
  AVAILABLE_BALANCE: {
    AVAILABLE_BALANCE_VALUE: "available-balance-value",
    AVAILABLE_NOTIONAL_BALANCE_VALUE: "available-notional-balance-value",
    OPEN_ORDERS_VALUE: "open-orders-value",
    PENDING_DEPOSITS_VALUE: "pending-deposits-value",
    PENDING_WITHDRAWAL_WARNING: "pending-withdrawal-warning",
    STAKING_BALANCE_VALUE: "staking-balance-value",
    TOTAL_BALANCE_VALUE: "total-balance-value",
    TOTAL_NOTIONAL_BALANCE_VALUE: "total-notional-balance-value",
    HOW_ARE_BALANCES_CALCULATED_LINK: "how-are-balances-calculated-link",
  },
  TRANSFER_DESTINATION: {
    EXCHANGE_OPTION: "transfer-destination-exchange-option",
    EXCHANGE_UNSUPPORTED_MSG: "transfer-destination-exchange-unsupported-msg",
    SELF_HOSTED_OPTION: "transfer-destination-self-hosted-option",
  },
  WHO_IS_RECIPIENT: {
    MYSELF: "who-is-recipient-myself",
    SOMEONE_ELSE: "who-is-recipient-someone-else",
  },
  ENTER_ADDRESS: {
    CLEAR: "enter-address-clear",
    CONTINUE: "enter-address-continue",
    COPY_FROM_CLIPBOARD: "enter-address-copy-from-clipboard",
    INPUT: "enter-address-input",
  },
  ADDRESS_NEEDS_VERIFICATION: {
    GO_TO_ADDRESS_BOOK_BUTTON: "address-needs-verification-go-to-address-book-button",
  },
  SELECT_EXCHANGE: {
    SEARCHABLE_INPUT: "select-exchange-searchable-input",
  },
  ATTESTATION_FORM: {
    SELECT_INDIVIDUAL_BUTTON: "attestation-form-select-individual-button",
    SELECT_ENTITY_BUTTON: "attestation-form-select-entity-button",
    FIRST_NAME_INPUT: "attestation-form-first-name-input",
    LAST_NAME_INPUT: "attestation-form-last-name-input",
    ENTITY_NAME_INPUT: "attestation-form-entity-name-input",
    COUNTRY_INPUT: "attestation-form-country-input",
    CONTINUE_BUTTON: "attestation-form-continue-button",
  },
  EXCHANGE_COMING_SOON: {
    CONTAINER: "exchange-coming-soon-container",
  },
};

export const DATA_LABELS = {
  ASSET_ERROR: {
    LOCKOUT_LABEL: "Lockout restriction",
    DEPOSITS_NOT_SUPPORTED_LABEL: "Deposits not supported",
    TRANSACTION_DISABLED_LABEL: "Transaction disabled",
  },
};
export const ARIA_LABELS = {
  ASSET_SEARCH: "asset-search",
  APPROVED_ADDRESS_SEARCH: "approved-address-search",
  AMOUNT_WITHDRAWAL_SCREEN: {
    DESTINATION_ADDRESS_CTA: "destination-address-cta",
    AMOUNT_INPUT: "amount-input",
  },
  EXCHANGE_SEARCH: "exchange-search",
};

export const AMOUNT_KEY = "amount";
export const INPUT_CURRENCY_TYPE_KEY = "inputCurrencyType";

export const INPUT_CURRENCY_TYPE = {
  CRYPTO: "crypto",
  FIAT: "fiat",
} as const;

export const CRYPTO_WITHDRAW_STATES = {
  LOADING: "LOADING",
  SELECT_ASSETS: "SELECT_ASSETS",
  DATA_LOAD_ERROR: "DATA_LOAD_ERROR",
  ASSETS_ERROR: "ASSETS_ERROR",
  AMOUNT_SCREEN: "AMOUNT_SCREEN",
  SELECT_APPROVED_ADDRESS: "SELECT_APPROVED_ADDRESS",
  NON_WHITELISTED_ADDRESS: "NON_WHITELISTED_ADDRESS",
  ADDRESS_MEMO: "ADDRESS_MEMO",
  WITHDRAWAL_WARNING: "WITHDRAWAL_WARNING",
  UK_ATTESTATION: "UK_ATTESTATION",
  MAS_ATTESTATION: "MAS_ATTESTATION",
  NEW_ADDRESS_FLOW: "NEW_ADDRESS_FLOW",
  COMPLETE_WITHDRAWAL: "COMPLETE_WITHDRAWAL",
  WITHDRAWAL_SUCCESS: "WITHDRAWAL_SUCCESS",
  AVAILABLE_BALANCE: "AVAILABLE_BALANCE",
  TRANSFER_DESTINATION: "TRANSFER_DESTINATION",
  WHO_IS_RECIPIENT: "WHO_IS_RECIPIENT",
  ENTER_ADDRESS: "ENTER_ADDRESS",
  ADDRESS_NEEDS_VERIFICATION: "ADDRESS_NEEDS_VERIFICATION",
  SELECT_EXCHANGE: "SELECT_EXCHANGE",
  ADDRESS_ATTESTATION: "ADDRESS_ATTESTATION",
} as const;

export const CRYPTO_WITHDRAW_EVENTS = {
  FETCH_INITIAL_DATA: "FETCH_INITIAL_DATA",
  GO_BACK: "GO_BACK",
  GO_TO_DATA_LOAD_ERROR: "GO_TO_DATA_LOAD_ERROR",
  SET_ADDRESS_CONTROLS: "SET_ADDRESS_CONTROLS",
  SET_CURRENCY_CONTROLS: "SET_CURRENCY_CONTROLS",
  ON_ADDRESS_CHANGE: "ON_ADDRESS_CHANGE",
  GO_TO_ADDRESS_MEMO: "GO_TO_ADDRESS_MEMO",
  ON_ATTESTATION_CHANGE: "ON_ATTESTATION_CHANGE",
  ADD_ACKNOWLEDGEMENT: "ADD_ACKNOWLEDGEMENT",
  ON_AMOUNT_CHANGE: "ON_AMOUNT_CHANGE",
  RESET_ADDRESS: "RESET_ADDRESS",
  ON_ADDRESS_CHANGE_IN_ADDRESS_MEMO: "ON_ADDRESS_CHANGE_IN_ADDRESS_MEMO",
  GO_TO_UK_ATTESTATION: "GO_TO_UK_ATTESTATION",
  SET_AMOUNT: "SET_AMOUNT",
  RESET_AMOUNT: "RESET_AMOUNT",
  ON_WITHDRAW_SUCCESS: "ON_WITHDRAW_SUCCESS",
  GO_TO_AVAILABLE_BALANCE: "GO_TO_AVAILABLE_BALANCE",
  SEND_TO_NEW_ADDRESS: "SEND_TO_NEW_ADDRESS",
  SEND_TO_SELF_HOSTED_WALLET: "SEND_TO_SELF_HOSTED_WALLET",
  SEND_TO_OTHER_EXCHANGE: "SEND_TO_OTHER_EXCHANGE",
  SELECT_NEW_ADDRESS_EXCHANGE: "SELECT_NEW_ADDRESS_EXCHANGE",
  SEND_TO_MYSELF: "SEND_TO_MYSELF",
  SEND_TO_SOMEONE_ELSE: "SEND_TO_SOMEONE_ELSE",
  SUBMIT_ATTESTATION_FORM_DATA: "SUBMIT_ATTESTATION_FORM_DATA",
} as const;
export const ADDRESS_TYPE = {
  MEMO: "memo",
  ADDRESS: "address",
  TAG: "tag",
} as const;

export const ACCOUNT_OR_GROUP = {
  ACCOUNT: "account",
  GROUP: "group",
} as const;

export const ADDRESS_FLOW_SOURCE = {
  ASSETS: "ASSETS",
  AMOUNT_ADDRESS: "AMOUNT_ADDRESS",
  AMOUNT_TAG: "AMOUNT_TAG",
} as const;

export enum WithdrawalAuthyPushStatus {
  PENDING = "Pending",
  APPROVED = "Approved",
  SUCCESS = "Success",
  FALLBACK = "Fallback",
  DENIED = "Denied",
}

export const FINAL = "final" as const;

export const CRYPTO_WITHDRAW_MIXPANEL_EVENTS = {
  START: {
    name: "Withdraw - Start",
    properties: {
      INITIATED_FROM: "Initiated From",
    },
  },
};
export const MIXPNAEL_PROPERTY_VALUE = {};
