import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { WithdrawalVasp } from "@gemini-ui/pages/transfers/Withdraw/withdrawalTravelRule";

export enum OutboundAttestationValues {
  Destination = "addressType",
  VaspId = "vaspId",
  AttestationType = "attestationType",
  EntityName = "entityName",
  FirstName = "firstName",
  LastName = "lastName",
  Country = "country",
  RecipientType = "recipientType",
}

export interface OutboundAttestation {
  addressType: AddressType;
  vaspId?: string;
  attestationType: string;
  entityName?: string;
  firstName?: string;
  lastName?: string;
  country?: CountryAbbreviation;
  recipientType?: string;
}

export interface TransferAttestationProps {
  attestation: OutboundAttestation;
  isTransferToExchangeUnsupported: boolean;
  onAttestationChange: (name: string, value: string) => void;
  supportedExchanges: WithdrawalVasp[];
}

export enum DestinationTypeRadioValues {
  Vasp = "vasp",
  Unhosted = "unhosted",
  Unknown = "unknown",
}

export type AddressType =
  | DestinationTypeRadioValues.Vasp
  | DestinationTypeRadioValues.Unhosted
  | DestinationTypeRadioValues.Unknown;

export enum AttestationTypeRadioValues {
  Self = "self",
  ThirdParty = "thirdParty",
}

export enum RecipientTypeRadioValues {
  Individual = "individual",
  Entity = "entity",
}
const INDIVIDUAL = "Individual";
const ENTITY = "Entity";
export const RecipientTypeLabels = {
  [RecipientTypeRadioValues.Individual]: INDIVIDUAL,
  [RecipientTypeRadioValues.Entity]: ENTITY,
};

export const INITIAL_ATTESTATION = {
  addressType: null,
  attestationType: null,
  recipientType: RecipientTypeRadioValues.Individual,
} as const;
